
import { mapGetters } from 'vuex'
export default {
    props: {
        playerBankName: {
            type: String,
            default: '-',
        },
        bankBranch: {
            type: String,
            default: '-',
        },
        bankAccountNumber: {
            type: String,
            default: '-',
        },
        isPrimary: {
            type: Boolean,
            default: false,
        },
        accountHolderName: {
            type: String,
            default: '-',
        },
        showDisableAccount: {
            type: Boolean,
            default: false,
        },
        bankId: {
            type: String,
            default: '',
        },
        currency: {
            type: String,
            default: '',
        },
        network: {
            type: String,
            default: '',
        },
    },
    computed: {
        nameLimiting() {
            if (!this.accountHolderName) return '-'
            if (this.accountHolderName.length > 20) {
                return this.accountHolderName?.substring(0, 20)
            }

            return this.accountHolderName
        },
        ...mapGetters({
            getCurrencyColor: 'finance/getCurrencyColor',
        }),
    },
    // methods: {
    //     shortenString(str) {
    //         if (!str) return
    //         if (str.length > 25) {
    //             return str.slice(0, 8) + '....' + str.slice(-8)
    //         }
    //         return str
    //     },
    // },
}
